/*@import "compass/css3";*/

.App {
    touch-action: none;
    /*font-family: 'Montserrat';*/
    -webkit-appearance: none;
}

#feedbackBar{
    position: absolute;
    width: 100%;
    height: 25px;
    background-color: white;
    text-align: center;
    z-index: 1;
    opacity : 0;
    /*color: black;*/
}
/*#top-canvas{*/
/*    z-index: 1;*/
/*}*/
#inner-div{
    z-index: 0; /*2 on top, 1 underneath*/
}

#cp-button{
    top: 15px; left: -15px;
    position: fixed;
    width: 30px;
    height: 25px;
    z-index: 4;
    border: solid 1px grey;
}

#controlPanel{
    position: fixed;
    left: -105vw;
    width: 35%;
    /*min-width: 80%;*/
    height: 110%;
    top: -2px;
    background-color: white;
    text-align: center;
    z-index: 5;
    color: black;
    -webkit-appearance: none;
    outline: solid grey 2px;
    /*filter: drop-shadow(15px 0px 10px grey);*/
    /*border-radius: 1vw;*/

}
@media screen and (max-width: 480px) {
    #controlPanel {
        width: 80%;
    }
}

#controlPanelBackground{
    position: absolute;
    width: 100%;
    height: 110%;
    z-index: 3;
    top: -2px;
    visibility: hidden
    /*opacity : 0;*/
}

#controlPanelRow{
    display: flex;
    /*padding-bottom: 4px;*/
    justify-content: space-between;
    width: 100%;
}

@keyframes move{
    50% { transform: translateY(-15px); }
}

text {
    font-size: 23px;
    opacity :0;
    display: inline-block;
    position: relative;
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    text-anchor: middle
}

#overlay{
    width: 100vw;
    height: 105vh;
    z-index: 3;
    top: -10px;
    position: absolute;
    pointer-events: none;
}
#overlayTop{
    width: 100%;
    height: 40%;
    position: absolute;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
}

#overlayBottom{
    width: 100%;
    height: 40%;
    position: absolute;
    top: 60%;
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
}

#hidden{
    pointer-events: none;
}
#hiddenTop{
    position: fixed;
    bottom:99%;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100%);
}
#hiddenBottom{
    position: fixed;
    bottom: -99%;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
}

#gradRectangle{
    position: fixed;
    left: 0px;
    /*background: linear-gradient(to bottom,  white 80%, rgba(0,0,0,.1) );*/
    z-index: 2;
    width: 10px;
    height: 10px;
    pointer-events: none;
}

#bub-canv{
    position : fixed;
    top:0;
    left: 0;
    z-index: 3;
    /*pointer-events: none;*/
}