:root {
    --col: white;

}

#bubble {
    position: fixed;
    /*-webkit-transform: translateZ(0);*/
    /*transform-origin: top left;*/
    /*transform: scale(.4);*/
    /*display: block;*/
    /*width: 200px;*/
    /*height: 180px;*/
    top: 0; right: 0;
    /*top: 30px; left: 30px;*/
    /*transition: opacity .5s linear, x 6s ease-in-out, y 6s ease-in-out;*/
    pointer-events: none;
    -webkit-appearance: none;
    /*filter: drop-shadow(0px 0px 4px black);*/
    /*border-radius: 1vw;*/
    z-index: 3;

}

#bubble polyline,
#bubble line,
#bubble path {
    fill: var(--col);
    /*stroke: white;*/
    /*stroke-width: 10;*/
    transition: 1s;
    paint-order: stroke;
}

#circle {
    /*stroke : white;*/
    /*stroke-width: 2;*/
    /*paint-order: stroke;*/
}


#circlesL, #circlesR{
    opacity: 0;
    stroke-width: 2;
    stroke: grey;
    fill: white;
    /*filter: drop-shadow(0px 0px 4px black);*/
}

.speechR, .speechL{
    position: relative;

}
