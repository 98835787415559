
body {
  overflow: hidden;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  margin: 0;
  padding: 0;
  /*background-color: rgb(243, 241, 239);*/
}
@font-face {
  font-family: 'Montserrat';
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400')
}
code {
  font-family: 'Montserrat';

}
.wrapper {
  transition: transform 0s ease;
  position: relative; /* make the wrapper element a positioned container */
  /*height:200px; !* set the height of the wrapper element *!*/
  /*width:1000px; !* set the height of the wrapper element *!*/

}

#canvas-wrapper {
  position: absolute; /* position the canvas wrapper element relative to the wrapper element */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.wrapper canvas {
  position: fixed; /* position the canvas wrapper element relative to the wrapper element */
  will-read-frequently : true;
  touch-action: auto;
  /*height: 100%; !* make the canvas element fill the entire height of the canvas wrapper element *!*/
  /*width: 100%;*/
}


.introPage {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  padding-top: 40vh;
  background-color: white;
  z-index: 5;
  font-size: 25px;
  font-family: 'Montserrat';

  animation: move 6s ease infinite;
}

@keyframes move{
  50% { transform: translateY(-30px); }
}
